import { useMemo, useState } from 'react'

import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { fillDisplayState } from './GraphQL/Queries/adapters'
import {
  AutoSchedulingTasksByCursorQuery,
  AutoSchedulingTasksByCursorQueryData,
} from './GraphQL'

type Props = {
  paging: CursorPage
  filter?: Gateway.AutoSchedulingTaskFilter
  sorting: Gateway.AutoSchedulingTaskSorting[]
  options?: Pick<QueryHookOptions, 'pollInterval' | 'fetchPolicy'>
}

export function useAutoSchedulingTasksByCursor({
  paging,
  filter,
  sorting,
  options,
}: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, refetch, fetchMore } = useApolloQuery<
    AutoSchedulingTasksByCursorQueryData,
    Gateway.QueryAutoSchedulingTasksByCursorArgs
  >(AutoSchedulingTasksByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      sorting,
      filter,
      paging: cursorPageToGraphqlPaging(paging),
    },
    ...options,
    onCompleted(data) {
      const newPageMeta = data.autoSchedulingTasksByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const autoSchedulingTasks = useMemo(
    () =>
      data?.autoSchedulingTasksByCursor?.edges.map(edge =>
        fillDisplayState(edge.node),
      ) ?? [],
    [data],
  )

  const loadMore = async (lastCursor: string) => {
    setLoadingMore(true)
    await fetchMore({
      variables: {
        paging: { limit: paging.size, startingAfter: lastCursor },
      },
    })

    setLoadingMore(false)
  }

  return {
    autoSchedulingTasks,
    loading,
    loadingInitial: !loadingMore && loading,
    loadingMore,
    currentPageMeta,
    refetch,
    loadMore,
  }
}
