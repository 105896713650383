import { RuleValidationAPI } from 'API/RuleValidation'

import { AutoSchedulingAssignmentAPI } from './AutoSchedulingAssignment'
import { AutoSchedulingTaskAPI } from './AutoSchedulingTask'
import { BackgroundTaskAPI } from './BackgroundTask'
import { ClassifiedTimeAPI } from './ClassifiedTime'
import { ClassifiersAPI } from './Classifiers'
import { CompanyAPI } from './Company'
import { CustomCvFieldsAPI } from './CustomCvField'
import { CustomCvFieldsValuesAPI } from './CustomCvFieldValues'
import { EmployeeAPI } from './Employee'
import { EmployeeJobAPI } from './EmployeeJob'
import { EmploymentLevelAPI } from './EmploymentLevel'
import { EmploymentLevelSettingAPI } from './EmploymentLevelSetting'
import { EmploymentTypeAPI } from './EmploymentType'
import { GroupAPI } from './Group'
import { IntegrationAPI } from './Integration'
import { IntegrationEventAPI } from './IntegrationEvent'
import { LocationAPI } from './Location'
import { MetricsAPI } from './Metrics'
import { PauseTimersAPI } from './PauseTimers'
import { PulledFileAPI } from './PulledFile'
import { ReportAPI } from './Report'
import { RuleAPI } from './Rule'
import { RulesetAPI } from './Ruleset'
import { ScheduleAPI } from './Schedule'
import { ShiftAPI } from './Shift'
import { ShiftJobsAPI } from './ShiftJob'
import { TimeBucketAPI } from './TimeBucket'
import { TimeCardAPI } from './TimeCard'
import { TimeEvaluationAPI } from './TimeEvaluation'
import { TimeEvaluationLogAPI } from './TimeEvaluationLog'
import { TimeTypesAPI } from './TimeTypes'
import { WorfklowAPI } from './Workflow'

export * from './AldoPayroll'
export * from './AvailabilityRequests'
export * from './BackgroundTask'
export * from './ClassifiedTime'
export * from './Company'
export * from './DayHourEquivalence'
export * from './EmployeeJob'
export * from './Group'
export * from './Integration'
export * from './IntegrationEvent'
export * from './Leaves'
export * from './Location'
export * from './Metrics'
export * from './NLPInference'
export * from './PauseTimers'
export * from './PayFrequency'
export * from './ProfileLogs'
export * from './PulledFile'
export * from './Report'
export * from './Rule'
export * from './RuleValidation'
export * from './Schedule'
export * from './Shift'
export * from './ShiftJob'
export * from './ShiftTag'
export * from './Staff'
export * from './TimeCard'
export * from './TimeEvaluation'
export * from './TimeEvaluationLog'
export * from './Timeoffs'
export * from './WorkWeek'

export const API = {
  AutoSchedulingTask: AutoSchedulingTaskAPI,
  AutoSchedulingAssignment: AutoSchedulingAssignmentAPI,
  BackgroundTask: BackgroundTaskAPI,
  Classifiers: ClassifiersAPI,
  ClassifiedTime: ClassifiedTimeAPI,
  Company: CompanyAPI,
  CustomCvFieldValues: CustomCvFieldsValuesAPI,
  CustomCvFields: CustomCvFieldsAPI,
  Employee: EmployeeAPI,
  EmployeeJob: EmployeeJobAPI,
  EmploymentLevel: EmploymentLevelAPI,
  EmploymentLevelSetting: EmploymentLevelSettingAPI,
  EmploymentType: EmploymentTypeAPI,
  Group: GroupAPI,
  Integration: IntegrationAPI,
  IntegrationEvent: IntegrationEventAPI,
  Location: LocationAPI,
  Metrics: MetricsAPI,
  PauseTimers: PauseTimersAPI,
  PulledFile: PulledFileAPI,
  Report: ReportAPI,
  Rule: RuleAPI,
  Ruleset: RulesetAPI,
  RuleValidation: RuleValidationAPI,
  Schedule: ScheduleAPI,
  Shift: ShiftAPI,
  ShiftJob: ShiftJobsAPI,
  TimeBucket: TimeBucketAPI,
  TimeCard: TimeCardAPI,
  TimeEvaluation: TimeEvaluationAPI,
  TimeEvaluationLog: TimeEvaluationLogAPI,
  TimeTypes: TimeTypesAPI,
  Workflow: WorfklowAPI,
}
