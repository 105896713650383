import { DateTime, Duration, IANAZone, Interval, WeekdayNumbers } from 'luxon'
import { RRuleWeekDay, Weekday } from 'Types/common'

import {
  NUMBER_OF_DAYS_IN_WEEK,
  ONE_HUNDRED_YEAR_DURATION,
  TWO_HUNDRED_YEAR_INTERVAL,
  WEEK_DAY_NUMBER,
  WEEK_START_DAY_OFFSET,
} from 'constants/dateTime'
import { WeekDay } from 'constants/gatewayGraphQL'
import { LONG_DASH } from 'constants/schedule'
import { DAY_SECONDS, SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from 'constants/time'

export function getCurrentWeek(
  weekStartDay: keyof typeof WEEK_START_DAY_OFFSET = WEEK_DAY_NUMBER.SUNDAY,
  timezone: string = 'local',
) {
  const { start, end } = getCurrentWeekLuxon(weekStartDay, timezone)

  return { start: start.toISODate(), end: end.toISODate() }
}

export function getCurrentWeekLuxon(
  weekStartDay: keyof typeof WEEK_START_DAY_OFFSET = WEEK_DAY_NUMBER.SUNDAY,
  timezone: string = 'local',
) {
  const start = DateTime.now()
    .setZone(timezone)
    .startOf('week')
    .minus({ day: WEEK_START_DAY_OFFSET[weekStartDay] })

  const end = start.plus({ days: 6 })

  return { start, end }
}

export function getPreviousWeek(
  weekStartDay: keyof typeof WEEK_START_DAY_OFFSET = WEEK_DAY_NUMBER.SUNDAY,
  timezone: string | undefined = 'local',
) {
  const start = DateTime.now()
    .setZone(timezone)
    .startOf('week')
    .minus({ day: WEEK_START_DAY_OFFSET[weekStartDay] })
    .minus({ weeks: 1 })
  const end = start.plus({ days: 6 })

  return { start: start.toISODate(), end: end.toISODate() }
}

export function getWeekDayNumbers(startDay: WeekdayNumbers): WeekdayNumbers[] {
  const weekDayNumbers: WeekdayNumbers[] = []

  for (let i = 0; i < NUMBER_OF_DAYS_IN_WEEK; i += 1) {
    const currentDay = ((i + startDay - 1) % NUMBER_OF_DAYS_IN_WEEK) + 1
    weekDayNumbers.push(currentDay as WeekdayNumbers)
  }

  return weekDayNumbers
}

export function datetimeListFromStartEnd({
  start,
  end,
}: {
  start: DateTime
  end: DateTime
}): DateTime[] {
  const interval = Interval.fromDateTimes(start, end)
  return Array.from(getDays(interval))
}

function* getDays(interval: Interval) {
  let cursor = interval.start.startOf('day')
  while (cursor <= interval.end) {
    yield cursor
    cursor = cursor.plus({ days: 1 })
  }
}

/** @deprecated */
export function formatISOToDay(timeISO: string) {
  if (!timeISO) {
    return ''
  }

  return DateTime.fromISO(timeISO).toFormat('cccc')
}

/**
 * Gets limits for today in local TZ
 * @returns {{startOn: string, finishOn: string}}
 */
export function getTodayLimits() {
  const start = DateTime.local().startOf('day')
  const finish = DateTime.local().endOf('day')

  return { startOn: start.toISODate(), finishOn: finish.toISODate() }
}

export function getWeekday(dateString: string) {
  const date = DateTime.fromISO(dateString)

  if (!date?.isValid) return null

  return date.weekday
}

/** To convert integer seconds from start of the day to human readable time  */
export function secondsToTime(seconds: number) {
  return DateTime.fromSeconds(seconds)
    .toUTC()
    .toLocaleString(DateTime.TIME_SIMPLE)
}

export function formatDuration(seconds: number): string {
  if (seconds === 0) return LONG_DASH
  return Duration.fromObject({ seconds }).toFormat('hh:mm')
}

export function formatISOToShortTime(timeISO: string) {
  if (!timeISO) {
    return ''
  }
  return DateTime.fromISO(timeISO).toLocaleString(DateTime.DATE_SHORT)
}

// TODO: Refactor this to return DateTimes instead and format somewhere else
export function jsDatesToRanges(
  dates: Date[],
): Gateway.DeluxePayrollRowsFilterPeriod[] {
  return dates
    .map((date, index, all) => {
      const from = DateTime.fromJSDate(date).toUTC().toISODate()

      let to = ''

      const nextItem = all?.at(index + 1)
      if (nextItem) {
        to = DateTime.fromJSDate(nextItem)
          .toUTC()
          .minus({ days: 1 })
          .toISODate()
      }

      return { from, to }
    })
    .slice(0, -1)
}

export function sortTwoDateTimes({
  a,
  b,
  order = 'asc',
  locationTimezone = 'local',
}: {
  a: DateTime
  b: DateTime
  order: 'asc' | 'desc'
  locationTimezone: string
}): number {
  const firstDate = a.setZone(locationTimezone)
  const nextDate = b.setZone(locationTimezone)

  if (order.toLowerCase() === 'asc') {
    return firstDate.toMillis() - nextDate.toMillis()
  }

  return nextDate.toMillis() - firstDate.toMillis()
}

export function sortArrayDateTimes({
  dateTimes,
  order = 'asc',
  locationTimezone = 'local',
}: {
  dateTimes: DateTime[]
  order: 'asc' | 'desc'
  locationTimezone: string
}): DateTime[] {
  return dateTimes.sort((a, b) =>
    sortTwoDateTimes({ a, b, order, locationTimezone }),
  )
}

export function fromStringsToInterval(
  start: string | null,
  end: string | null,
  format = 'yyyy-MM-dd',
): Interval {
  const intervalStart = start ? DateTime.fromFormat(start, format) : null
  const intervalEnd = end ? DateTime.fromFormat(end, format) : null

  let interval = TWO_HUNDRED_YEAR_INTERVAL

  if (intervalStart && !intervalEnd) {
    interval = Interval.after(intervalStart, ONE_HUNDRED_YEAR_DURATION)
  } else if (!intervalStart && intervalEnd) {
    interval = Interval.before(intervalEnd, ONE_HUNDRED_YEAR_DURATION)
  } else if (intervalStart && intervalEnd) {
    interval = Interval.fromDateTimes(intervalStart, intervalEnd)
  }

  return interval
}

/** @deprecated */
export function isBeforeNow(
  date: string | null,
  timezone: string,
  format = 'yyyy-MM-dd',
): boolean {
  const dateToCompare = date
    ? DateTime.fromFormat(date, format, { zone: timezone })
    : DateTime.now().plus(ONE_HUNDRED_YEAR_DURATION)

  const diff = dateToCompare
    .diff(DateTime.now().setZone(timezone), 'hours')
    .toObject()

  return diff.hours ? diff.hours < 0 : false
}

export function mapGatewayWeekdayToLuxon(
  gatewayWeekday: Gateway.WeekDay,
): WeekdayNumbers {
  switch (gatewayWeekday) {
    case WeekDay.Monday:
      return 1
    case WeekDay.Tuesday:
      return 2
    case WeekDay.Wednesday:
      return 3
    case WeekDay.Thursday:
      return 4
    case WeekDay.Friday:
      return 5
    case WeekDay.Saturday:
      return 6
    case WeekDay.Sunday:
      return 7
    default:
      return 1
  }
}

export function mapToRRuleWeekDay(weekDay: Weekday) {
  if (weekDay === 7) return 0
  return weekDay
}

export function mapToLuxonWeekDay(weekDay: Weekday) {
  if (weekDay === 0) return 7
  return weekDay
}

export function mapLuxonToGatewayWeekday(
  weekDay: WeekdayNumbers,
): Gateway.WeekDay {
  switch (weekDay) {
    case 1:
      return WeekDay.Monday
    case 2:
      return WeekDay.Tuesday
    case 3:
      return WeekDay.Wednesday
    case 4:
      return WeekDay.Thursday
    case 5:
      return WeekDay.Friday
    case 6:
      return WeekDay.Saturday
    case 7:
      return WeekDay.Sunday
    default:
      return WeekDay.Monday
  }
}

export function mapRRuleWeekdayToDictionary(rruleWeekDay: RRuleWeekDay) {
  switch (rruleWeekDay) {
    case 0:
      return WeekDay.Monday
    case 1:
      return WeekDay.Tuesday
    case 2:
      return WeekDay.Wednesday
    case 3:
      return WeekDay.Thursday
    case 4:
      return WeekDay.Friday
    case 5:
      return WeekDay.Saturday
    case 6:
      return WeekDay.Sunday

    default:
      return ''
  }
}

export function jsDateOrNull(value: string | null) {
  return value ? DateTime.fromISO(value).toJSDate() : null
}

export function earlierThan(date?: string | null) {
  return date
    ? {
        before: DateTime.fromISO(date).plus({ days: 1 }).toJSDate(),
      }
    : undefined
}

export function laterThan(date?: string | null) {
  return date
    ? {
        after: DateTime.fromISO(date).minus({ days: 1 }).toJSDate(),
      }
    : undefined
}

export function zoneOffsetName(timezone: string, locale?: string) {
  return IANAZone.create(timezone).offsetName(DateTime.now().toMillis(), {
    format: 'short',
    locale,
  })
}

export function secondsToHours(seconds: number): number {
  return seconds / SECONDS_IN_HOUR
}
export function secondsToDays(seconds: number): number {
  return seconds / DAY_SECONDS
}

export function minutesToSeconds(minutes: number): number {
  return minutes * SECONDS_IN_MINUTE
}

export function secondsToMinutes(seconds: number): number {
  return seconds / SECONDS_IN_MINUTE
}
